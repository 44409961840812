import styled from "styled-components";
import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const StyledWrapper = styled.div`
  .random-background {
    padding: ${props => props.theme["percent-10"]} 0
      ${props => props.theme["percent-10"]} 0;

    @media (min-width: 994px) and (max-width: 1120px) {
      min-width: 285px;
    }
  }

  [class^="col"] {
    display: flex;
    flex-direction: column;
  }

  [class^="col"] div {
    flex-grow: 1;
  }
`;

const StatisticBox = ({ statistic, StatsHeading }) => {
  const [focus, setFocus] = React.useState(false);

  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100 padding-left-8 padding-right-8">
        <h1>{StatsHeading && StatsHeading.title && StatsHeading.title}</h1>
        <div className="row">
          {statistic.length > 0 &&
            React.Children.toArray(
              statistic.map(stats => (
                <div className="col-12 col-md-6 col-lg-4 mt-4">
                  <div
                    className="random-background d-flex justify-content-center padding-left-8 padding-right-8"
                    style={{ backgroundColor: `${stats.color}` }}
                  >
                    <div>
                      <CountUp
                        duration={2}
                        end={stats.numbers !== null ? stats.numbers : "-"}
                        start={focus ? 100 : null}
                        suffix={stats.suffix}
                        prefix={stats.prefix}
                        separator=","
                        decimals={stats.decimals}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={isVisible => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                            delayedCall
                          >
                            <span
                              ref={countUpRef}
                              className="statistic-number"
                            />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      {/* <div className="statistic-text" dangerouslySetInnerHTML={{ __html: stats.description }} /> */}
                      <p className="statistic-text">{stats.description}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default StatisticBox;
